








































































































































#header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  font-family: 'Orbitron', sans-serif;
}
.logo {
  margin-left: 70px;
  @media (max-width: 1699px) {
    margin-left: 0px;
  }
}
.logo-header {
  animation: logo 1.2s linear infinite;
  transition: 0.5s;
  @media (max-width: 1499px) {
    width: auto;
    height: 80px;
  }
  @media (max-width: 991px) {
    width: auto;
    height: 60px;
  }
}
.logo-header:hover {
      transform: scale(1.1);
    }
@keyframes logo {
  25% {
    transform: rotateY(0);
  }
  50% {
    transform: rotateY(90deg);
  }
  75% {
    transform: rotateY(0);
  }
}
.navbar-expand-xl {
  .container-fluid {
    @media (max-width: 1399px) {
      padding: 0px 15px !important;
    }
  }
}
.bg-image {
  background-image: url('~@/assets/images/Header/Artboard 11.png');
}
.container-fluid {
  padding-left: 0;
  padding-right: 0;
}
.navbar-collapse {
}
.nav-item {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: bold;
  gap: 1rem;
  margin: 0px 15px;
  @media (max-width: 1799px) {
    font-size: 20px;
  }
  @media (max-width: 1599px) {
    font-size: 18px;
    margin: 0px 10px;
  }
  @media (max-width: 1399px) {
    margin: 0px 5px;
  }
  @media (max-width: 1299px) {
    font-size: 17px;
    margin: 0px 0px;
  }
}

.nav-link {
  color: #a0d1a0;
  text-decoration: none;
}

.active {
  color: rgb(52, 248, 52);
}

.nav-link:hover {
  color: rgb(6, 248, 6);
}

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.custom-toggler.navbar-toggler {
  border-color: rgb(255, 255, 255);
}
