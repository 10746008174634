@media (min-width: 1024px) {
    .xxl-3 {
        flex: 0 0 500px;
        max-width: 500px;
    }
    .xxl-9 {
        flex: 1 1 auto;
        max-width: calc(100% - 500px);
    }
}
@media (min-width: 1441px) {
    .content-page {
        .container,
        .container-sm,
        .container-md,
        .container-lg,
        .container-xl {
            max-width: 1200px;
        }
    }
}
@media (min-width: 2000px) {
    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
        max-width: 1440px;
    }
}
@media (min-width: 2560px) {
    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
        max-width: 1600px;
    }
}
@media (min-width: 3000px) {
    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
        max-width: 2100px;
    }
}
