
@import url('https://fonts.googleapis.com/css2?family=Goldman:wght@400;700&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
:root {
  --breakpoint-xxl: 1440;
}
html {
  transition: all ease-in 0.1s;
  padding: 0 !important;
  margin: 0 !important;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  font-weight: 400;
  font-style: normal;
  color: #333;
  font-family: 'IBM Plex Sans', sans-serif !important;
}
body {
  font-family: 'IBM Plex Sans', sans-serif !important;
}
