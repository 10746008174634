// Your variable overrides
$body-bg: #fff;
$body-color: #111;

@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/bootstrap-vue/src/index.scss';
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('./font.scss');
@import url('./customerVariables.scss');
@import url('./theme.scss');
@import url('./media.scss');

html {
  font-family: 'IBM Plex Sans', sans-serif !important;
}
body {
  padding: 0px !important;
  font-family: "Space Mono", monospace;
  overflow-x: hidden;
}
