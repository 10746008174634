































.eggsbook {
  overflow-x: hidden;
}
.content-page {
  margin-top: 140px;
  @media (max-width: 1499px) {
    margin-top: 120px;
  }
  @media (max-width: 991px) {
    margin-top: 100px;
  }
}
